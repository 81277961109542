import './App.css';
import {} from 'reactstrap';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="header-1">
          Candlelight Books
        </h1>
        <p>Books for young readers that magnify Christ and nurture maturity.</p>
        <p><img src="/resources/firemen-cover.jpg" width="400px" alt="firemen book" /></p>
        <p>$3.00 - <a href="/resources/firemen-demo.pdf" target="blank">PDF Sample</a></p>
        <p>Check back soon for more!</p>
      </header>
      <footer>
        <p>Copyright 2021, Nathaniel Maxson | njmaxson@gmail.com</p>
        <p><a href="https://www.vecteezy.com/free-vector/candle">Candle Vectors by Vecteezy</a></p>
      </footer>
    </div>
  );
}

export default App;
